import React, { useContext, useState, useEffect } from "react"
import * as styles from "./success.module.css"
import Logo from "components/tools/logo.js"
import modalContext from "context/modalContext"
import { navigate } from "gatsby"
import Deed from "components/payments/deed"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import api from "apis"
import { setProductListAsync } from "store/modules/payInfoReducer.js"
const Success = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [scbInfo, setScbInfo] = useState({})

  //현재 구독상태인지 확인
  const dispatch = useDispatch()
  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()
      setScbInfo(scbInfo)
      dispatch(setProductListAsync())
    })()
  }, [])

  const { list: goodsList } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )
  const name = scbInfo.name

  const onDeed = () => {
    setModalMain(
      <Deed
        close={() => {
          setmodalOpen(false)
        }}
        billing_no={scbInfo.billing_no}
        goodsList={goodsList}
      />
    )

    setmodalOpen(true)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.con}>
        <Logo w={206.36} h={46} white={true} navCancel={true} />
      </div>
      <div>
        <div className={styles.box}>
          <div className={`colContainer ${styles.welcome}`}>
            {scbInfo?.isScb ? (
              <>
                <div>
                  <p
                    name={"customer"}
                    style={{ textAlign: "center", lineHeight: "45.5px" }}
                  >
                    {name}님, 구독해주셔서 <br />
                    감사합니다
                    <div className={styles.dot}>
                      <div>.</div>
                      <div>.</div>
                    </div>
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      letterSpacing: "0px",
                      lineHeight: "23.5px",
                    }}
                  >
                    {name}님은 피치서가 구독 회원입니다.
                    <br />
                    피치서가에서 쉬운 글을 읽어보세요.
                  </p>
                </div>
                <button
                  className={styles.loginBox}
                  onClick={() => navigate("/front/")}
                  style={{
                    minHeight: "47px",
                  }}
                >
                  피치서가 시작하기
                </button>
                <a onClick={onDeed} style={{ cursor: "pointer" }}>
                  영수증 보기
                </a>
              </>
            ) : (
              <>
                <div style={{ marginTop: "auto" }}>
                  피치서가를 먼저 구독 해주세요
                </div>
                <button
                  className={styles.loginBox}
                  style={{
                    marginTop: "0px",
                  }}
                  onClick={() => navigate("/front")}
                >
                  메인으로 가기
                </button>
              </>
            )}
          </div>
        </div>
        {/* <div className={styles.con}>
      <Logo size={{ w: 268, h: 60 }} navCancel={true} />
      {scbInfo?.isScb ? (
        <p name={"customer"}>{name}님,쉬운 글 세상에 오신걸 환영합니다.</p>
      ) : null}
      <img src={subSuccess} alt="successImg" />
      <div className={styles.box}>
        {scbInfo?.isScb ? (
          <>
            <p>
              <br /> 지금 피치서가 PC뷰어를 다운 받고 쉬운 글 콘텐츠를
              읽어보세요.
            </p>
            <button
              className={styles.redBtn}
              onClick={() => navigate("/pcViewer/")}
              style={{
                minHeight: "47px",
                margin: "30px 0",
              }}
            >
              PC뷰어 다운로드
            </button>
            <a onClick={onDeed}>영수증 보기</a>
          </>
        ) : (
          <>
            <div style={{ marginTop: "auto" }}>
              피치서가를 먼저 구독 해주세요
            </div>
            <button
              className={styles.redBtn}
              style={{
                marginTop: "0px",
              }}
              onClick={() => navigate("/front")}
            >
              메인으로 가기
            </button>
          </>
        )} */}
      </div>
    </div>
  )
}

export default Success
