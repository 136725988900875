import React, { useCallback, useEffect, useState } from "react"
import { getFooterAsync } from "store/modules/footerReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import stamp from "images/stamp.png"
import InputTitle from "components/tools/InputTitle.js"
import { getDateYMD } from "utils"
import * as styles from "./payments.module.css"
import { payMethodTable } from "utils/data.js"
import api from "apis"
const Deed = props => {
  const dispatch = useDispatch()

  const getFooter = useCallback(() => dispatch(getFooterAsync()), [dispatch])

  const { footer } = useSelector(
    ({ footerReducer: { data } }) => data,
    shallowEqual
  )

  const [billingInfo, setBillingInfo] = useState({})

  useEffect(() => {
    ;(async () => {
      getFooter()
      const info = await api.billingDetail(props.billing_no)
      setBillingInfo(info)
    })()
  }, [])

  const goods = props.goodsList.get(billingInfo?.goods_no)
  console.log(billingInfo.goods_no)
  console.log(props.goodsList)

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        width: "100%",
        height: "100%",
      }}
      onClick={props.close}
    >
      <div className={styles.deedCon}>
        <div style={{ padding: "0 20px" }}>
          <InputTitle name={"영수증"} titleSize={16} />
        </div>
        <div className={styles.deedItem}>
          {/* <div className={styles.deedContents}>
            <div>거래번호</div>
            <div>test</div>
          </div> */}
          <div className={styles.deedContents}>
            <div>거래일자</div>
            <div>
              {getDateYMD(
                billingInfo?.pg_paid_at === 0
                  ? billingInfo?.registerTime
                  : billingInfo?.pg_paid_at,
                "YYYY.MM.DD HH:mm"
              )}
            </div>
          </div>

          {billingInfo.billing_method === 99 ? null : (
            <>
              <div className={styles.deedContents}>
                <div>구매자</div>
                <div>{billingInfo?.pg_buyer}</div>
              </div>
              <div className={styles.deedContents}>
                <div>상품금액</div>
                <div>{goods?.price?.toLocaleString() + "원"}</div>
              </div>
              <div className={styles.deedContents}>
                <div>할인금액</div>
                <div>
                  {billingInfo?.bonus_discount?.toLocaleString() + "원"}
                </div>
              </div>
              <div className={styles.deedContents}>
                <div>결제금액</div>
                <div>
                  {billingInfo?.billing_amount?.toLocaleString() + "원"}
                </div>
              </div>
            </>
          )}
          <div className={styles.deedContents}>
            <div>결제수단</div>
            <div>{payMethodTable.get(billingInfo?.billing_method)}</div>
          </div>
        </div>
        <hr />
        <div
          className={styles.deedItem}
          style={{
            paddingRight: 10,
          }}
        >
          <div className={styles.deedContents}>
            <div>구독상품</div>
            <div>{goods?.title}</div>
          </div>
          {/* <div className={styles.deedContents}>
            <div>구독기간</div>
            <div>{`${getDateYMD(
              billingInfo?.subscribe_begin,
              "YYYY년MM월DD일"
            )}~${getDateYMD(
              billingInfo?.subscribe_end,
              "YYYY년MM월DD일"
            )}`}</div>
          </div> */}
        </div>
        <hr style={{ marginBottom: "10px" }} />
        <div className={styles.infoBox}>
          <div>
            <div>피치마켓</div>
            <div>사업자 등록번호: {footer?.company_reg_number}</div>
            <div>대표자: {footer?.company_ceo}</div>
            <div>소재지: {footer?.company_address} </div>
          </div>
          <img src={stamp} alt="" />
        </div>
        <button
          className={styles.redBtn}
          style={{
            border: "none",
            backgroundColor: "#e95c63",
            color: "#fff",
            borderRadius: "25px",
            fontSize: "16px",
            height: "47px",
            cursor: "pointer",
          }}
          onClick={() => {
            globalThis.print()
          }}
        >
          인쇄하기
        </button>
      </div>
    </div>
  )
}

export default Deed
// import { license } from "images"
// import modalContext from "context/modalContext"
// const { setmodalOpen, setModalMain } = useContext(modalContext)
// const onLisence = () => {
//   setmodalOpen(true)
//   setModalMain(
//     <div
//       className={styles.license}
//       style={{
//         backgroundColor: "rgba(0, 0, 0, 0)",
//       }}
//       onClick={() => {
//         setmodalOpen(false)
//       }}
//     >
//       <img src={license} />
//     </div>
//   )
// }
