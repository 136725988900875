// extracted by mini-css-extract-plugin
export var product = "payments-module--product--i-NJ_";
export var plan = "payments-module--plan--2IJQG";
export var coupon = "payments-module--coupon--2dzN5";
export var couponBox = "payments-module--couponBox--3SK7t";
export var couponItem = "payments-module--couponItem--1XyhA";
export var means = "payments-module--means--pjcC0";
export var stickyBox = "payments-module--stickyBox--3JmQo";
export var payInfo = "payments-module--payInfo--3aw9U";
export var redBtn = "payments-module--redBtn--1-jjF";
export var redCircleBtn = "payments-module--redCircleBtn--2tgfT";
export var license = "payments-module--license--1PdHQ";
export var btnBox = "payments-module--btnBox--8VU6D";
export var flexCon = "payments-module--flexCon--2HcJ0";
export var flexItem = "payments-module--flexItem--1iq10";
export var flexMenu = "payments-module--flexMenu--3NQxt";
export var printBtn = "payments-module--printBtn--59Lvt";
export var deedCon = "payments-module--deedCon--2l-H-";
export var deedItem = "payments-module--deedItem--2nOqM";
export var deedContents = "payments-module--deedContents--pquJV";
export var infoBox = "payments-module--infoBox--1ePDJ";
export var couponCon = "payments-module--couponCon--1u1YY";